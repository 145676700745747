// Home.js
import React, { forwardRef } from "react";
import SEO from "./SEO";
import "../App.css";
import Slideshow from "./Slideshow";
import AboutSection from "./AboutSection";
import img1 from "./bk1.jpg";
import img2 from "./slide2.webp";
import img3 from "./bk3.jpg";
import { motion } from "framer-motion";
import Attribution from "./Attribution";
import Menu from "./Menu";
import Footer from "./Footer";
import AINotifications from "./AINotifications";
import AIEasyImplementation from "./AIEasyImplementation";
import Professional from "./Professional";
import { useTranslation, Trans } from "react-i18next";
import ProductShowcase from "./ProductShowcase";

export const HtmlRenderer = ({ htmlContent }) => {
  return <div dangerouslySetInnerHTML={{ __html: htmlContent }} />;
};

const Home = forwardRef((props, ref) => {
  // Wrap Home in forwardRef
  const { t } = useTranslation();

  return (
    <>
      <SEO />
      <Menu />

      {/* Background Parallax Effect (adjust as needed for page scrolling) */}
      <div className="slide" id="home">
        {" "}
        {/* Set a fixed height for each "page" */}
        {/* Slide 1: Slideshow */}
        <Slideshow>
          <div className="slideshow-slide" key={1}>
            <img src={img1} alt={t("home.slideshow.slide1.image_alt")} />
            <div className="text-overlay">
              <motion.h1
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ delay: 0.2, duration: 0.6 }}
                className="text-5xl md:text-7xl font-extrabold bg-clip-text text-transparent bg-gradient-to-r from-neonGreen to-lightGreen"
              >
                <HtmlRenderer htmlContent={t("home.slideshow.slide1.title")} />
              </motion.h1>
              <p>
                <Trans
                  i18nKey="home.slideshow.slide1.description"
                  components={{ strong: <strong /> }}
                />
              </p>
              <br />
              <br />
              <motion.a
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                className="mt-8 px-8 py-4 bg-green-500 text-black rounded-full text-lg font-bold hover:bg-green-400 transition-colors"
                href="/about"
                style={{ marginRight: 10 }}
              >
                {t("home.slideshow.slide1.buttons.about_me")}
              </motion.a>
              <motion.a
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                className="mt-8 px-8 py-4 bg-green-500 text-black rounded-full text-lg font-bold hover:bg-green-400 transition-colors"
                href="/contact"
              >
                {t("home.slideshow.slide1.buttons.get_more_info")}
              </motion.a>
            </div>
          </div>

          <div className="slideshow-slide" key={2}>
            <img src={img2} alt={t("home.slideshow.slide2.image_alt")} />
            <div className="text-overlay">
              <motion.h1
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ delay: 0.2, duration: 0.6 }}
                className="text-5xl md:text-7xl font-extrabold bg-clip-text text-transparent bg-gradient-to-r from-neonGreen to-lightGreen"
              >
                <HtmlRenderer htmlContent={t("home.slideshow.slide2.title")} />
              </motion.h1>{" "}
              <p>{t("home.slideshow.slide2.description")}</p>
              <br />
              <br />
              <motion.a
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                className="mt-8 px-8 py-4 bg-green-500 text-black rounded-full text-lg font-bold hover:bg-green-400 transition-colors"
                style={{ marginRight: 10 }}
              >
                {t("home.slideshow.slide2.buttons.get_more_info")}
              </motion.a>
              <motion.a
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                className="mt-8 px-8 py-4 bg-green-500 text-black rounded-full text-lg font-bold hover:bg-green-400 transition-colors"
                href="https://www.linkedin.com/in/renzo-dupont-b9797941/details/recommendations/"
              >
                {t("home.slideshow.slide2.buttons.about_me")}
              </motion.a>
            </div>
          </div>

          <div className="slideshow-slide" key={3}>
            <img src={img3} alt={t("home.slideshow.slide3.image_alt")} />
            <div className="text-overlay">
              <motion.h1
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ delay: 0.2, duration: 0.6 }}
                className="text-5xl md:text-7xl font-extrabold bg-clip-text text-transparent bg-gradient-to-r from-neonGreen to-lightGreen"
              >
                <HtmlRenderer htmlContent={t("home.slideshow.slide3.title")} />
              </motion.h1>{" "}
              <p>{t("home.slideshow.slide3.description")}</p>
              <br />
              <br />
              <motion.a
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                className="mt-8 px-8 py-4 bg-green-500 text-black rounded-full text-lg font-bold hover:bg-green-400 transition-colors"
                style={{ marginRight: 10 }}
                href="https://www.linkedin.com/in/renzo-dupont-b9797941/details/recommendations/"
              >
                {t("home.slideshow.slide3.buttons.get_more_info")}
              </motion.a>
              <motion.a
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                className="mt-8 px-8 py-4 bg-green-500 text-black rounded-full text-lg font-bold hover:bg-green-400 transition-colors"
                href="/about"
              >
                {t("home.slideshow.slide3.buttons.about_me")}
              </motion.a>
            </div>
          </div>
        </Slideshow>
      </div>

      {/* About Section */}
      <div className="slide" id="about">
        <motion.div
          initial={{ opacity: 0, x: -30 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ duration: 0.8 }}
        >
          <AboutSection />
        </motion.div>
      </div>

      {/* Attribution Section */}
      <div className="slide">
        <motion.div
          initial={{ opacity: 0, x: -40 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ duration: 0.8 }}
        >
          <Attribution />
        </motion.div>
      </div>

      {/* AI Easy Implementation Section */}
      <div className="slide">
        <motion.div
          initial={{ opacity: 0, x: -40 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ duration: 0.8 }}
        >
          <AIEasyImplementation />
        </motion.div>
      </div>

      {/* Intelligent Web Development Section */}
      <div className="slide" id="services">
        <motion.div
          initial={{ opacity: 0, x: 60 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ duration: 0.8 }}
          style={{ width: "100%" }}
        >
          <ProductShowcase />
        </motion.div>
      </div>

      <div className="slide">
        <motion.div
          initial={{ opacity: 0, x: -40 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ duration: 0.8 }}
        >
          <AINotifications />
        </motion.div>
      </div>

      {/* Professional Experience Section */}
      <div className="slide" id="experience-timeline">
        <motion.div
          initial={{ opacity: 0, x: -40 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ duration: 0.8 }}
        >
          <Professional />
        </motion.div>
      </div>

      {/* Page 8: Footer/Contact (If it's a full page) */}
      <div className="slide">
        <Footer />
      </div>
    </>
  );
});

export default Home;
