// AINotifications.js
import React from "react";
import CodeEffect from "./CodeEffect";
import { useTranslation } from "react-i18next";
import { HtmlRenderer } from "./Home";

const AINotifications = () => {
  const { t } = useTranslation();
  const codeEffects = t("ai_notifications.code_effects", {
    returnObjects: true,
  });

  return (
    <section className="py-12 ">
      <div className="content text-center code-effect-section-container">
        <h2 className="text-5xl md:text-7xl font-extrabold bg-clip-text text-transparent bg-gradient-to-r from-neonGreen to-lightGreen">
          <HtmlRenderer htmlContent={t("ai_notifications.title")} />
        </h2>
        <p className="mt-4 text-lg text-gray-400">
          {t("ai_notifications.description")}
        </p>
        <br />
        <br />
        <br />
        <div className="services-grid">
          <div
            className="service-card"
            style={{ backgroundColor: "transparent", padding: "2px" }}
          >
            <CodeEffect arr={codeEffects[0]} />
          </div>
          <div
            className="service-card"
            style={{ backgroundColor: "transparent", padding: "2px" }}
          >
            <CodeEffect arr={codeEffects[1]} />
          </div>
          <div
            className="service-card"
            style={{ backgroundColor: "transparent", padding: "2px" }}
          >
            <CodeEffect arr={codeEffects[2]} />
          </div>
        </div>
      </div>
    </section>
  );
};

export default AINotifications;
