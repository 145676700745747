import { motion } from "framer-motion";
import {
  SiNodedotjs,
  SiReact,
  SiWordpress,
  SiTailwindcss,
  SiMongodb,
  SiGithub,
  SiVercel,
  SiFigma,
  SiNextdotjs,
  SiTypescript,
  SiDocker,
} from "react-icons/si";

const icons = [
  { id: 1, icon: <SiNodedotjs size={50} className="text-green-500" /> },
  { id: 2, icon: <SiReact size={50} className="text-blue-500" /> },
  { id: 3, icon: <SiWordpress size={50} className="text-blue-600" /> },
  { id: 4, icon: <SiTailwindcss size={50} className="text-cyan-500" /> },
  { id: 5, icon: <SiMongodb size={50} className="text-green-700" /> },
  { id: 6, icon: <SiGithub size={50} className="text-gray-500" /> },
  { id: 7, icon: <SiVercel size={50} className="text-white" /> },
  { id: 8, icon: <SiFigma size={50} className="text-pink-500" /> },
  { id: 9, icon: <SiNextdotjs size={50} className="text-white" /> },
  { id: 10, icon: <SiTypescript size={50} className="text-blue-500" /> },
  { id: 11, icon: <SiDocker size={50} className="text-blue-600" /> },
];

const IconSlider = () => {
  return (
    <div
      className="overflow-hidden w-full py-10"
      style={{ marginTop: "20px", marginBottom: "5px" }}
    >
      <motion.div
        className="flex space-x-20 w-[200%]" // Ensures both lists fit side by side
        animate={{
          x: ["0", "-100%"], // Moves both lists left
        }}
        transition={{
          ease: "linear",
          duration: 20, // Speed of scrolling
          repeat: Infinity, // Loops infinitely
        }}
      >
        {/* Duplicating the list to create an infinite loop */}
        {[...icons, ...icons].map((item, index) => (
          <div
            key={index}
            className="flex-shrink-0 opacity-50 grayscale hover:opacity-100 hover:grayscale-0 transition-all duration-300"
          >
            {item.icon}
          </div>
        ))}
      </motion.div>
    </div>
  );
};

export default IconSlider;
