import React, { useState } from "react";
import { HashLink } from "react-router-hash-link";
import { useLocation } from "react-router-dom";
import logo from "./logo.png";
import { useTranslation } from "react-i18next";

const Menu = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isSubMenuOpen, setIsSubMenuOpen] = useState(false);
  const [isSubMenuOpen2, setIsSubMenuOpen2] = useState(false);

  const toggleMenu = () => setIsMenuOpen(!isMenuOpen);
  const handleSubMenuToggle = (item) => {
    if (item === 1) setIsSubMenuOpen((prev) => !prev);
    if (item === 2) setIsSubMenuOpen2((prev) => !prev);
  };
  const handleLinkClick = (closeMenu = true) => {
    if (closeMenu) toggleMenu();
  };

  // helper to remove hash from the "to" prop
  const getPath = (to) => {
    const hashIndex = to.indexOf("#");
    return hashIndex !== -1 ? to.substring(0, hashIndex) : to;
  };

  const isActive = (to) => location.pathname === getPath(to);

  return (
    <div className="navbar">
      {/* Hamburger Menu for Mobile */}
      <div className="menu-icon" onClick={toggleMenu}>
        <div className="bar"></div>
        <div className="bar"></div>
        <div className="bar"></div>
      </div>
      {/* Navbar Links */}
      <div className={`nav-links ${isMenuOpen ? "nav-links-mobile" : ""}`}>
        <img src={logo} alt={t("menu.logo_alt")} className="logo" />

        <HashLink
          smooth
          to="/#home"
          onClick={() => handleLinkClick()}
          className={`menu-link relative py-2 px-4 transition-colors duration-300 ${
            isActive("/")
              ? "text-neonGreen font-bold border-b-2 border-neonGreen"
              : "text-gray-700"
          }`}
        >
          {t("menu.links.home")}
        </HashLink>

        <HashLink
          smooth
          to="/ai-implementation"
          onClick={() => handleLinkClick()}
          className={`menu-link relative py-2 px-4 transition-colors duration-300 ${
            isActive("/ai-implementation")
              ? "text-neonGreen font-bold border-b-2 border-neonGreen"
              : "text-gray-700"
          }`}
        >
          {t("menu.links.aiImplenentation")}
        </HashLink>

        <HashLink
          smooth
          to="/website-design-development"
          onClick={() => handleLinkClick()}
          className={`menu-link relative py-2 px-4 transition-colors duration-300 ${
            isActive("/website-design-development")
              ? "text-neonGreen font-bold border-b-2 border-neonGreen"
              : "text-gray-700"
          }`}
        >
          {t("menu.links.webDevelopment")}
        </HashLink>

        <HashLink
          smooth
          to="https://ui.startupp.ai"
          onClick={isMenuOpen ? toggleMenu : undefined}
          className="menu-link relative py-2 px-4 transition-colors duration-300 text-gray-700"
        >
          {t("menu.links.login")}
        </HashLink>

        {/* About Me Submenu */}
        <div
          className="menu-item relative py-2 px-4 transition-colors duration-300"
          onMouseEnter={() => setIsSubMenuOpen(true)}
          onMouseLeave={() => setIsSubMenuOpen(false)}
          onClick={() => handleSubMenuToggle(1)}
        >
          <span
            className={`${
              isActive("/about") ? "text-neonGreen font-bold" : "text-white-700"
            }`}
          >
            {t("menu.submenus.about_me.title")}
          </span>
          <div className={`submenu ${isSubMenuOpen ? "submenu-open1" : ""}`}>
            <HashLink
              smooth
              to="https://www.linkedin.com/in/renzo-dupont-b9797941/details/recommendations/"
              onClick={isMenuOpen ? toggleMenu : undefined}
              target="_blank"
              className="block py-2 px-4 text-gray-700 hover:text-neonGreen transition-colors"
            >
              {t("menu.submenus.about_me.items.recommendations")}
            </HashLink>
            <HashLink
              smooth
              to="/contact"
              onClick={isMenuOpen ? toggleMenu : undefined}
              className={`block py-2 px-4 transition-colors ${
                isActive("/contact")
                  ? "text-neonGreen font-bold"
                  : "text-gray-700 hover:text-neonGreen"
              }`}
            >
              {t("menu.submenus.about_me.items.get_in_touch")}
            </HashLink>
            <div className="separator my-2"></div>
            <HashLink
              smooth
              to="/about"
              onClick={isMenuOpen ? toggleMenu : undefined}
              className={`block py-2 px-4 transition-colors ${
                isActive("/about")
                  ? "text-neonGreen font-bold"
                  : "text-gray-700 hover:text-neonGreen"
              }`}
            >
              {t("menu.submenus.about_me.items.about_me")}
            </HashLink>
          </div>
        </div>

        {/* Resources Submenu */}
        <div
          className="menu-item relative py-2 px-4 transition-colors duration-300"
          onMouseEnter={() => setIsSubMenuOpen2(true)}
          onMouseLeave={() => setIsSubMenuOpen2(false)}
          onClick={() => handleSubMenuToggle(2)}
        >
          <span
            className={`${
              isActive("/blog/maximizing-daily-productivity-with-chatgpt") ||
              isActive("/blog/top-5-prompts-for-managers")
                ? "text-neonGreen font-bold"
                : "text-white-700"
            }`}
          >
            {t("menu.submenus.resources.title")}
          </span>
          <div className={`submenu ${isSubMenuOpen2 ? "submenu-open2" : ""}`}>
            <HashLink
              smooth
              to="/blog/maximizing-daily-productivity-with-chatgpt"
              onClick={isMenuOpen ? toggleMenu : undefined}
              className={`block py-2 px-4 transition-colors ${
                isActive("/blog/maximizing-daily-productivity-with-chatgpt")
                  ? "text-neonGreen font-bold"
                  : "text-gray-700 hover:text-neonGreen"
              }`}
            >
              {t("menu.submenus.resources.items.maximizing_productivity")}
            </HashLink>
            <HashLink
              smooth
              to="/blog/top-5-prompts-for-managers"
              onClick={isMenuOpen ? toggleMenu : undefined}
              className={`block py-2 px-4 transition-colors ${
                isActive("/blog/top-5-prompts-for-managers")
                  ? "text-neonGreen font-bold"
                  : "text-gray-700 hover:text-neonGreen"
              }`}
            >
              {t("menu.submenus.resources.items.top_prompts")}
            </HashLink>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Menu;
