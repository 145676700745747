// src/components/SEO.js
import React from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

const SEO = () => {
  const { t } = useTranslation();
  const location = useLocation();
  // Or figure out domain via `window.location.origin` if you’re on client side
  // But for SSR, pick the domain based on language or environment

  const baseUrls = {
    en: "https://startupp.ai",
    es1: "https://benjaminlabs.com",
    es2: "https://greenfenix.com.uy",
  };

  // In your real code, decide which domain is correct for the current language
  const domain = baseUrls.en;
  const canonicalUrl = `${domain}${location.pathname}`;

  // Build hreflang entries for each language/domain
  const hreflangs = [
    { lang: "en", url: `${baseUrls.en}${location.pathname}` },
    { lang: "es", url: `${baseUrls.es}${location.pathname}` },
    { lang: "es-uy", url: `${baseUrls["es-uy"]}${location.pathname}` },
  ];

  return (
    <Helmet>
      <title>{t("about_title")}</title>
      <meta name="description" content={t("about_description")} />
      <meta
        name="keywords"
        content="Website design, Website development, AI implementation, artificial intelligence solutions, custom AI integration, 
machine learning consulting, business automation, Salesforce development, web development, 
full-stack development, AI consulting, enterprise AI solutions, startup technology solutions, 
ChatGPT integration, LLM implementation, cloud AI solutions, AI optimization, 
business process automation, custom algorithm development, React development, 
website design, digital transformation"
      />
      <meta name="robots" content="index, follow" />
      <meta name="viewport" content="width=device-width, initial-scale=1" />
      <meta property="og:type" content="website" />
      <meta property="og:title" content={t("about_title")} />
      <meta property="og:description" content={t("about_description")} />
      <meta property="og:url" content={canonicalUrl} />
      {/* Canonical Tag */}
      <link rel="canonical" href={canonicalUrl} />
      {/* Hreflang tags */}
      {hreflangs.map(({ lang, url }) => (
        <link key={lang} rel="alternate" href={url} hreflang={lang} />
      ))}
      <meta name="robots" content="index, follow" />
    </Helmet>
  );
};

export default SEO;
