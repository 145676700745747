import { motion } from "framer-motion";
import { useTranslation } from "react-i18next";
import conceptImg from "./concept.jpg";
import designImg from "./design.jpg";
import aiDevImg from "./aidevelopment.jpg";
import testingImg from "./testing.jpg";
import deploymentImg from "./deployment.jpg";

const MvpDelivery = () => {
  const { t } = useTranslation();
  const images = [conceptImg, designImg, aiDevImg, testingImg, deploymentImg]; // Ensure enough images

  return (
    <div id="mvp" className="relative w-full py-16">
      <motion.h2
        className="text-4xl font-bold text-center mb-6"
        initial={{ opacity: 0, y: -10 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
      >
        {t("mvp_delivery.title")}
      </motion.h2>
      <p className="text-lg text-center max-w-3xl mx-auto mb-12">
        {t("mvp_delivery.subtitle")}
      </p>

      <div className="relative flex flex-col items-center">
        {/* Vertical Timeline Line */}
        <div className="absolute left-1/2 transform -translate-x-1/2 w-1 h-full bg-gradient-to-b from-primary to-transparent"></div>

        {t("mvp_delivery.steps", { returnObjects: true }).map((step, index) => (
          <motion.div
            key={index}
            className={`flex flex-wrap items-center justify-between max-w-6xl w-full mb-12 p-8 gap-8 ${
              index % 2 === 0 ? "lg:flex-row" : "lg:flex-row-reverse"
            }`}
            initial={{ opacity: 0, x: index % 2 === 0 ? -100 : 100 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.5, delay: index * 0.2 }}
          >
            {/* Image Section */}
            <motion.div
              initial={{ opacity: 0, x: -30 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ duration: 0.8 }}
              className="w-full sm:w-full lg:w-1/2 flex justify-center"
            >
              <img
                src={images[index % images.length]}
                alt={step.title}
                className="rounded-lg shadow-md w-full h-auto object-cover"
              />
            </motion.div>

            {/* Text Section */}
            <div className="flex flex-col text-left max-w-lg w-full sm:w-full lg:w-1/2">
              <h3 className="text-2xl font-semibold">{step.title}</h3>
              <p className="mt-2">{step.description}</p>
            </div>
          </motion.div>
        ))}
      </div>

      {/* Floating Blur Effect */}
      <div className="absolute top-0 left-1/4 w-32 h-32 bg-primary opacity-20 blur-3xl"></div>
      <div className="absolute bottom-0 right-1/4 w-32 h-32 bg-primary opacity-20 blur-3xl"></div>
    </div>
  );
};

export default MvpDelivery;
