import React from "react";
import { motion } from "framer-motion";
import { useTranslation } from "react-i18next";
import { HtmlRenderer } from "./Home";
import product1 from "./analyzer.jpg";
import product2 from "./scraper.jpg";
import product3 from "./planner.jpg";

const ProductShowcase = () => {
  const { t } = useTranslation();

  const productsFromTranslation = t("productShowcase", {
    returnObjects: true,
  })[0];

  // Map each product to include the correct image based on its index
  const products = productsFromTranslation.map((product, index) => {
    let image;
    switch (index) {
      case 0:
        image = product1;
        break;
      case 1:
        image = product2;
        break;
      case 2:
        image = product3;
        break;
      default:
        image = "";
    }
    return {
      id: index,
      image,
      title: product.title,
      description: product.description,
    };
  });

  return (
    <div className="max-w-screen-xl mx-auto px-4 py-8 space-y-12">
      {products.map((product, index) => (
        <div
          key={product.id}
          className="flex flex-col md:flex-row items-center md:space-x-8"
        >
          <motion.div
            className="md:w-1/2"
            initial={{ opacity: 0, x: -50 }}
            whileInView={{ opacity: 1, x: 0 }}
            viewport={{ once: true }}
            transition={{ duration: 0.5, delay: index * 0.2 }}
          >
            <img
              src={product.image}
              alt={product.title}
              className="w-full h-auto rounded-lg"
            />
          </motion.div>
          <motion.div
            className="md:w-1/2 mt-4 md:mt-0"
            initial={{ opacity: 0, x: 50 }}
            whileInView={{ opacity: 1, x: 0 }}
            viewport={{ once: true }}
            transition={{ duration: 0.5, delay: index * 0.2 }}
          >
            <h3 className="text-3xl font-bold">{product.title}</h3>
            <HtmlRenderer htmlContent={product.description} />
            <motion.a
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              className="mt-8 px-8 py-4 bg-green-500 text-black rounded-full text-lg font-bold hover:bg-green-400 transition-colors"
              href="/contact"
            >
              {index === 0
                ? t("cta.button1")
                : index === 1
                ? t("cta.button3")
                : t("cta.button4")}
            </motion.a>
          </motion.div>
        </div>
      ))}
    </div>
  );
};

export default ProductShowcase;
