// About.js
import React from "react";
import SEO from "./SEO";
import "../App.css";
import Menu from "./Menu";
import { motion } from "framer-motion";
import profileImg from "./me.jpeg";
import Footer from "./Footer";
import { useTranslation } from "react-i18next";

function About() {
  const { t } = useTranslation();

  const sectionVariants = {
    hidden: { opacity: 0, y: 30 },
    visible: (i = 1) => ({
      opacity: 1,
      y: 0,
      transition: {
        delay: i * 0.3,
        duration: 0.6,
      },
    }),
  };

  return (
    <>
      <SEO />
      <Menu />
      <div className="about-me-container">
        <motion.img
          src={profileImg}
          alt={t("about.profile_alt")}
          className="profile-image"
          initial="hidden"
          animate="visible"
          variants={sectionVariants}
          custom={1}
        />

        <motion.h1
          initial="hidden"
          animate="visible"
          variants={sectionVariants}
          custom={2}
          className="text-5xl md:text-7xl font-extrabold bg-clip-text text-transparent bg-gradient-to-r from-neonGreen to-lightGreen"
        >
          {t("about.title")}
        </motion.h1>

        <motion.h2
          initial="hidden"
          animate="visible"
          variants={sectionVariants}
          custom={3}
        >
          {t("about.subtitle")}
        </motion.h2>

        <motion.p
          initial="hidden"
          animate="visible"
          variants={sectionVariants}
          custom={4}
        >
          {t("about.intro")}
        </motion.p>

        {/* Journey of Innovation */}
        <motion.h3
          initial="hidden"
          animate="visible"
          variants={sectionVariants}
          custom={5}
        >
          {t("about.sections.journey_of_innovation.title")}
        </motion.h3>

        <motion.p
          initial="hidden"
          animate="visible"
          variants={sectionVariants}
          custom={6}
        >
          {t("about.sections.journey_of_innovation.content")}
        </motion.p>

        {/* Pioneering AI Solutions */}
        <motion.h3
          initial="hidden"
          animate="visible"
          variants={sectionVariants}
          custom={7}
        >
          {t("about.sections.pioneering_ai_solutions.title")}
        </motion.h3>

        <motion.p
          initial="hidden"
          animate="visible"
          variants={sectionVariants}
          custom={8}
        >
          {t("about.sections.pioneering_ai_solutions.content_1")}
        </motion.p>

        <motion.p
          initial="hidden"
          animate="visible"
          variants={sectionVariants}
          custom={9}
        >
          {t("about.sections.pioneering_ai_solutions.content_2")}
        </motion.p>

        {/* Professional Expertise */}
        <motion.h3
          initial="hidden"
          animate="visible"
          variants={sectionVariants}
          custom={10}
        >
          {t("about.sections.professional_expertise.title")}
        </motion.h3>

        <motion.ul
          initial="hidden"
          animate="visible"
          variants={sectionVariants}
          custom={11}
        >
          <li>
            <strong>
              {t(
                "about.sections.professional_expertise.items.ai_implementation"
              )}
            </strong>
          </li>
          <li>
            <strong>
              {t(
                "about.sections.professional_expertise.items.custom_algorithm"
              )}
            </strong>
          </li>
          <li>
            <strong>
              {t("about.sections.professional_expertise.items.salesforce_erp")}
            </strong>
          </li>
          <li>
            <strong>
              {t("about.sections.professional_expertise.items.full_stack")}
            </strong>
          </li>
        </motion.ul>

        {/* Leadership & Collaboration */}
        <motion.h3
          initial="hidden"
          animate="visible"
          variants={sectionVariants}
          custom={12}
        >
          {t("about.sections.leadership_collaboration.title")}
        </motion.h3>

        <motion.p
          initial="hidden"
          animate="visible"
          variants={sectionVariants}
          custom={13}
        >
          {t("about.sections.leadership_collaboration.paragraphs.intro")}
        </motion.p>

        <motion.ul
          initial="hidden"
          animate="visible"
          variants={sectionVariants}
          custom={14}
        >
          <li>
            <strong>
              {t(
                "about.sections.leadership_collaboration.items.project_management"
              )}
            </strong>
          </li>
          <li>
            <strong>
              {t(
                "about.sections.leadership_collaboration.items.team_leadership"
              )}
            </strong>
          </li>
          <li>
            <strong>
              {t(
                "about.sections.leadership_collaboration.items.cross_project_collaboration"
              )}
            </strong>
          </li>
        </motion.ul>

        <motion.p
          initial="hidden"
          animate="visible"
          variants={sectionVariants}
          custom={15}
        >
          {t("about.sections.leadership_collaboration.conclusion")}
        </motion.p>

        {/* Global Perspective */}
        <motion.h3
          initial="hidden"
          animate="visible"
          variants={sectionVariants}
          custom={16}
        >
          {t("about.sections.global_perspective.title")}
        </motion.h3>

        <motion.p
          initial="hidden"
          animate="visible"
          variants={sectionVariants}
          custom={17}
        >
          {t("about.sections.global_perspective.content")}
        </motion.p>

        {/* Client-Centered Approach */}
        <motion.h3
          initial="hidden"
          animate="visible"
          variants={sectionVariants}
          custom={18}
        >
          {t("about.sections.client_centered_approach.title")}
        </motion.h3>

        <motion.p
          initial="hidden"
          animate="visible"
          variants={sectionVariants}
          custom={19}
        >
          {t("about.sections.client_centered_approach.paragraphs.intro")}
        </motion.p>

        <motion.ul
          initial="hidden"
          animate="visible"
          variants={sectionVariants}
          custom={20}
        >
          <li>
            <strong>
              {t(
                "about.sections.client_centered_approach.items.budget_management"
              )}
            </strong>
          </li>
          <li>
            <strong>
              {t(
                "about.sections.client_centered_approach.items.pre_built_solutions"
              )}
            </strong>
          </li>
          <li>
            <strong>
              {t(
                "about.sections.client_centered_approach.items.professionalism"
              )}
            </strong>
          </li>
          <li>
            <strong>
              {t(
                "about.sections.client_centered_approach.items.technical_support"
              )}
            </strong>
          </li>
        </motion.ul>

        <motion.p
          initial="hidden"
          animate="visible"
          variants={sectionVariants}
          custom={21}
        >
          {t("about.sections.client_centered_approach.conclusion")}
        </motion.p>

        {/* Let's Collaborate */}
        <motion.h3
          initial="hidden"
          animate="visible"
          variants={sectionVariants}
          custom={22}
        >
          {t("about.sections.lets_collaborate.title")}
        </motion.h3>

        <motion.p
          initial="hidden"
          animate="visible"
          variants={sectionVariants}
          custom={23}
        >
          {t("about.sections.lets_collaborate.content_1")}
        </motion.p>

        <motion.p
          initial="hidden"
          animate="visible"
          variants={sectionVariants}
          custom={24}
        >
          {t("about.sections.lets_collaborate.content_2")}
        </motion.p>
      </div>

      <Footer />
    </>
  );
}

export default About;
