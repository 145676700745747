import React, { useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import example1 from "./example1.jpg";
import design2 from "./design2.png";
import design3 from "./design3.png";
import design4 from "./design4.png";
import design5 from "./design5.png";
import design6 from "./design6.png";
import design7 from "./design7.png";
import design8 from "./design8.png";
import { useTranslation } from "react-i18next";
import { HtmlRenderer } from "./Home";

const IntelligentWebDevelopment = () => {
  const { t } = useTranslation();
  const [isHovered, setIsHovered] = useState(null);

  const designs = [
    {
      image: example1,
      href: "https://www.credilit.com.uy",
      alt: t("intelligent_web_development.projects.credilit_sa"),
      hoverText: t("intelligent_web_development.hover_texts.credilit_sa"),
    },
    {
      image: design2,
      href: "#",
      alt: t("intelligent_web_development.projects.portfolio_design"),
      hoverText: t("intelligent_web_development.hover_texts.portfolio_design"),
    },
    {
      image: design3,
      href: "#",
      alt: t("intelligent_web_development.projects.portfolio_design"),
      hoverText: t("intelligent_web_development.hover_texts.portfolio_design"),
    },
    {
      image: design4,
      href: "#",
      alt: t("intelligent_web_development.projects.portfolio_design"),
      hoverText: t("intelligent_web_development.hover_texts.portfolio_design"),
    },
    {
      image: design5,
      href: "#",
      alt: t("intelligent_web_development.projects.portfolio_design"),
      hoverText: t("intelligent_web_development.hover_texts.portfolio_design"),
    },
    {
      image: design6,
      href: "#",
      alt: t("intelligent_web_development.projects.portfolio_design"),
      hoverText: t("intelligent_web_development.hover_texts.portfolio_design"),
    },
    {
      image: design7,
      href: "#",
      alt: t("intelligent_web_development.projects.portfolio_design"),
      hoverText: t("intelligent_web_development.hover_texts.portfolio_design"),
    },
    {
      image: design8,
      href: "#",
      alt: t("intelligent_web_development.projects.portfolio_design"),
      hoverText: t("intelligent_web_development.hover_texts.portfolio_design"),
    },
  ];

  const responsive = {
    superLargeDesktop: { breakpoint: { max: 4000, min: 1024 }, items: 3 },
    desktop: { breakpoint: { max: 1024, min: 768 }, items: 3 },
    tablet: { breakpoint: { max: 768, min: 480 }, items: 1 },
    mobile: { breakpoint: { max: 480, min: 0 }, items: 1 },
  };

  return (
    <div>
      <div style={{ padding: "0 10px" }}>
        <motion.h2
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
          style={{ textAlign: "center" }}
          className="text-5xl md:text-7xl font-extrabold bg-clip-text text-transparent bg-gradient-to-r from-neonGreen to-lightGreen"
        >
          <HtmlRenderer htmlContent={t("intelligent_web_development.title")} />
        </motion.h2>
        <motion.p
          initial={{ opacity: 0, x: -20 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ delay: 0.2, duration: 0.8 }}
          className="text-lg md:text-xl text-center mb-12 leading-relaxed"
        >
          {t("intelligent_web_development.description")}
        </motion.p>
      </div>
      <div className="w-full max-w-[1200px] mx-auto px-2 sm:px-4 overflow-hidden">
        <Carousel
          responsive={responsive}
          infinite={true}
          autoPlay={true}
          autoPlaySpeed={3000}
          keyBoardControl={true}
          showDots={false}
          arrows={false}
          centerMode={false}
          rewind={true}
          rewindWithAnimation={true}
          itemClass="flex items-center justify-center px-2"
          containerClass="w-full"
        >
          {designs.map((design, index) => {
            return (
              <div
                key={index}
                className={`relative group transition-all duration-500 opacity-100`}
              >
                <motion.a
                  href={design.href}
                  className="block w-full h-[400px] relative overflow-hidden rounded-lg flex justify-center items-center"
                  onMouseEnter={() => setIsHovered(index)}
                  onMouseLeave={() => setIsHovered(null)}
                >
                  <motion.img
                    src={design.image}
                    alt={design.alt}
                    className="w-full h-full max-h-[500px] object-cover rounded-lg"
                  />
                  <AnimatePresence>
                    {isHovered === index && (
                      <motion.div
                        className="absolute inset-0 bg-black bg-opacity-90 flex items-center justify-center"
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                        transition={{ duration: 0.3 }}
                      >
                        <p className="text-white text-xl font-bold">
                          {design.hoverText}
                        </p>
                      </motion.div>
                    )}
                  </AnimatePresence>
                </motion.a>
              </div>
            );
          })}
        </Carousel>
      </div>
    </div>
  );
};

export default IntelligentWebDevelopment;
