import { motion } from "framer-motion";
import { useTranslation } from "react-i18next";
import { HtmlRenderer } from "./Home";
import Footer from "./Footer";
import Menu from "./Menu";
import SEO from "./SEO";
import IntelligentWebDevelopment from "./IntelligentWebDevelpment";
import {
  ChartBarIcon,
  ShieldCheckIcon,
  CodeBracketIcon,
  DevicePhoneMobileIcon,
  RocketLaunchIcon,
} from "@heroicons/react/24/solid";
import IconSlider from "./IconSlider";

const WebsiteDesignPage = () => {
  const { t } = useTranslation();
  const servicesMore = t("websiteDesign.servicesMore", { returnObjects: true });

  // Add stats section data
  const stats = [
    {
      icon: (
        <RocketLaunchIcon className="w-24 h-24 text-green-500 hover:scale-110 hover:rotate-6  transition-all duration-300 ease-in-out" />
      ),
      value: "100+",
      label: "Projects around the world",
    },
    {
      icon: (
        <ChartBarIcon className="w-24 h-24 text-green-500 hover:scale-110 hover:rotate-6  transition-all duration-300 ease-in-out" />
      ),
      value: "100%",
      label: "Budget friendly & unique designs",
    },
    {
      icon: (
        <ShieldCheckIcon className="w-24 h-24 text-green-500 hover:scale-110 hover:rotate-6  transition-all duration-300 ease-in-out" />
      ),
      value: "100%",
      label: "Secure sites using the latest technology",
    },
  ];

  return (
    <>
      <SEO
        title={t("websiteDesign.metaTitle")}
        description={t("websiteDesign.metaDescription")}
      />
      <Menu />

      <div id="ai-implementation-page" className="slide">
        {/* Enhanced Hero Section with Floating Elements */}
        <div className="slide text-white py-32 relative overflow-hidden bg-opacity-50">
          <div className="absolute inset-0">
            <div className="grid grid-cols-8 grid-rows-8 gap-4 opacity-10">
              {[...Array(64)].map((_, i) => (
                <motion.div
                  key={i}
                  className="w-full h-full bg-green-500"
                  initial={{ opacity: 0 }}
                  animate={{ opacity: [0.1, 0.3, 0.1] }}
                  transition={{ duration: 2, repeat: Infinity, delay: i * 0.1 }}
                />
              ))}
            </div>
          </div>

          <motion.div
            initial={{ opacity: 0, y: -40 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8 }}
            className="text-center mx-auto px-6 max-w-4xl relative z-10"
          >
            <h1 className="text-5xl md:text-7xl font-extrabold bg-clip-text text-transparent bg-gradient-to-r from-neonGreen to-lightGreen">
              <HtmlRenderer htmlContent={t("websiteDesign.heroTitle")} />
            </h1>
            <p className="mt-6 text-xl md:text-2xl text-gray-300">
              {t("websiteDesign.heroDescription")}
            </p>

            <motion.a
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              href="/contact"
              className="mt-8 px-8 py-4 bg-green-500 text-black rounded-full text-lg font-bold hover:bg-green-400 transition-colors"
            >
              {t("home.slideshow.slide1.buttons.get_more_info")}
            </motion.a>
          </motion.div>
        </div>

        <IconSlider />

        <motion.div
          initial={{ opacity: 0, x: -40 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ duration: 0.8 }}
          className="w-full"
          style={{ marginTop: "40px" }}
        >
          <IntelligentWebDevelopment />
        </motion.div>

        {/* Stats Section */}
        <div className="py-20">
          <div className="max-w-6xl mx-auto px-4">
            <motion.h2
              initial={{ opacity: 0 }}
              whileInView={{ opacity: 1 }}
              className="text-4xl font-bold text-center text-white mb-16"
            >
              Our Numbers
            </motion.h2>

            <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
              {stats.map((stat, index) => (
                <motion.div
                  key={index}
                  initial={{ opacity: 0, y: 20 }}
                  whileInView={{ opacity: 1, y: 0 }}
                  transition={{ delay: index * 0.2 }}
                  className="p-6 rounded-lg hover:bg-gray-700 transition-colors flex flex-col items-center justify-center text-center"
                >
                  {stat.icon}
                  <div className="text-5xl font-bold text-white mb-2">
                    {stat.value}
                  </div>
                  <p className="text-gray-400">{stat.label}</p>
                </motion.div>
              ))}
            </div>
          </div>
        </div>

        {/* Process Timeline */}
        <div className="py-20">
          <div className="max-w-6xl mx-auto px-4 text-center">
            <motion.h2
              initial={{ opacity: 0, y: -20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.8 }}
              className="text-3xl md:text-5xl font-bold strong"
            >
              <HtmlRenderer htmlContent={t("websiteDesign.servicesTitle")} />
            </motion.h2>
            <p className="mt-4 text-lg text-gray-400">
              {t("websiteDesign.servicesDescription")}
            </p>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            {[
              {
                icon: (
                  <CodeBracketIcon className="w-24 h-24 text-green-500 hover:scale-110 hover:rotate-6  transition-all duration-300 ease-in-out" />
                ),
                title: servicesMore[0].title,
                desc: servicesMore[0].description,
              },
              {
                icon: (
                  <DevicePhoneMobileIcon className="w-24 h-24 text-green-500 hover:scale-110 hover:rotate-6 transition-all duration-300 ease-in-out" />
                ),
                title: servicesMore[1].title,
                desc: servicesMore[1].description,
              },
              {
                icon: (
                  <RocketLaunchIcon className="w-24 h-24 text-green-500 hover:scale-110 hover:rotate-6 transition-all duration-300 ease-in-out" />
                ),
                title: servicesMore[2].title,
                desc: servicesMore[2].description,
              },
            ].map((step, index) => (
              <motion.div
                key={index}
                initial={{ opacity: 0, x: -20 }}
                whileInView={{ opacity: 1, x: 0 }}
                transition={{ delay: index * 0.2 }}
                className="p-6 rounded-lg hover:bg-gray-700 transition-colors flex flex-col items-center justify-center text-center"
              >
                {step.icon}
                <h3 className="text-xl font-bold text-white mb-2">
                  {step.title}
                </h3>
                <p className="text-gray-400">{step.desc}</p>
              </motion.div>
            ))}
          </div>
        </div>
      </div>

      {/* Additional Features Section */}
      <section className="py-12 text-white text-center px-4">
        <motion.h2
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
          className="text-3xl md:text-5xl font-bold"
        >
          {t("websiteDesign.additionalFeaturesTitle")}
        </motion.h2>
        <p className="mt-4 text-lg text-gray-300">
          {t("websiteDesign.additionalFeaturesDescription")}
        </p>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-1 mt-8">
          {t("websiteDesign.extraFeatures", { returnObjects: true }).map(
            (feature, index) => (
              <motion.div
                key={index}
                className="service-card bg-gray-900rounded-lg p-6 shadow-md hover:shadow-lg transition-shadow duration-300"
                whileHover={{ scale: 1.05, y: -10 }}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.8, delay: index * 0.2 }}
              >
                <h3 className="text-2xl font-bold">{feature.title}</h3>
                <p className="mt-2 text-gray-300">{feature.description}</p>
              </motion.div>
            )
          )}
        </div>
      </section>

      {/* Footer Section */}
      <div className="slide">
        <Footer />
      </div>
    </>
  );
};

export default WebsiteDesignPage;
