// ProfileSection.js
import React from "react";
import { motion } from "framer-motion";
import profileImg from "./team.jpg";
import Testimonials from "./Testimonials";
import CodeEffect from "./CodeEffect";
import { useTranslation, Trans } from "react-i18next";
import { HtmlRenderer } from "./Home";

const ProfileSection = () => {
  const { t } = useTranslation();
  const codeEffects = t("profile_section.code_effects", {
    returnObjects: true,
  });

  return (
    <div className="profile-container">
      {/* Profile Section */}
      <motion.div
        className="profile-card"
        initial={{ opacity: 0, x: -30 }}
        animate={{ opacity: 1, x: 0 }}
        transition={{ duration: 0.8 }}
      >
        <img
          src={profileImg}
          alt={t("profile_section.image_alt")}
          className="profile-img rounded-lg"
        />
        <div className="profile-info mt-4">
          <h1 className="text-5xl md:text-7xl font-extrabold bg-clip-text text-transparent bg-gradient-to-r from-neonGreen to-lightGreen">
            <HtmlRenderer htmlContent={t("profile_section.title")} />
          </h1>

          <p className="mt-4 text-lg">
            <Trans
              i18nKey="profile_section.description"
              components={{ strong: <strong /> }}
            />
          </p>

          <div
            className="service-card mobile-hidden"
            style={{ backgroundColor: "transparent", padding: "2px" }}
          >
            <CodeEffect arr={codeEffects} />
          </div>
        </div>
      </motion.div>

      {/* Experience Section */}
      <Testimonials />
    </div>
  );
};

export default ProfileSection;
