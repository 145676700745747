import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import "./index-mobile.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { HashRouter } from "react-router-dom"; // Import BrowserRouter
import { ParallaxProvider } from "react-scroll-parallax";
import "./i18n"; // Import i18n configuration

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <HashRouter>
    <ParallaxProvider>
      <App />
    </ParallaxProvider>
  </HashRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
