import { motion } from "framer-motion";
import { Trans, useTranslation } from "react-i18next";
import { HtmlRenderer } from "./Home";
import Footer from "./Footer";
import Attribution from "./Attribution";
import Menu from "./Menu";
import SEO from "./SEO";
import MvpDelivery from "./MvpDelivery";

const AIImplementationPage = () => {
  const { t } = useTranslation();
  const servicesMore = t("attribution.servicesMore", { returnObjects: true });

  return (
    <>
      <SEO />
      <Menu />

      {/* Attribution Section */}
      <div id="ai-implementation-page" className="slide">
        <div className="slide text-white py-32">
          <motion.div
            initial={{ opacity: 0, y: -40 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8 }}
            className="text-center mx-auto px-6 max-w-4xl"
          >
            <h1 className="text-5xl md:text-7xl font-extrabold bg-clip-text text-transparent bg-gradient-to-r from-neonGreen to-lightGreen">
              <HtmlRenderer htmlContent={t("attribution.titleMore")} />
            </h1>
            <p className="mt-4 text-lg md:text-xl text-gray-300">
              {t("attribution.descriptionMore")}
            </p>
            <motion.a
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              href="/contact"
              className="mt-8 px-8 py-4 bg-green-500 text-black rounded-full text-lg font-bold hover:bg-green-400 transition-colors"
            >
              {t("home.slideshow.slide1.buttons.get_more_info")}
            </motion.a>
          </motion.div>
        </div>

        <motion.div
          initial={{ opacity: 0, x: -40 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ duration: 0.8 }}
          className="w-full"
        >
          <Attribution />
        </motion.div>

        <MvpDelivery />

        <section className="py-12">
          {/* Dark background */}
          <div className="mx-auto px-4">
            <div className="grid grid-cols-1 md:grid-cols-1 gap-2">
              {/* Cards Section */}
              <div className="grid grid-cols-1 md:grid-cols-1 gap-2 ">
                {servicesMore.map((service, index) => (
                  <motion.div
                    key={index}
                    className="service-card ai rounded-lg shadow-md hover:shadow-xl transition-shadow duration-300 p-6 flex flex-col relative overflow-hidden"
                    whileHover={{ scale: 1.05, y: -10 }}
                    style={{
                      textAlign: "center",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.8, delay: index * 0.2 }}
                  >
                    <h3 className="services-slider-card-title text-2xl md:text-4xl font-bold mb-4 strong">
                      {service.title}
                    </h3>
                    <div className="services-slider-card-description text-lg text-gray-400 text-center">
                      <Trans
                        i18nKey={`attribution.servicesMore.${index}.description`}
                        components={{
                          strong: <strong />,
                          ul: <ul className="list-disc ml-5 mt-2" />,
                          li: <li />,
                        }}
                      />
                    </div>
                  </motion.div>
                ))}
              </div>
            </div>
          </div>
        </section>
      </div>

      {/* Page 8: Footer/Contact (If it's a full page) */}
      <div className="slide">
        <Footer />
      </div>
    </>
  );
};

export default AIImplementationPage;
